<template>
    <router-link :to="{ name: 'CourseDetail', params: {slug:item.slug, id:item.course_id }}"  >
        <div class="courseGrid">
            <div class="courseImage">
                <img :src="itemImages" class="img-fluid" alt="course" >
            </div>
            <span class="discount-offer" v-if="isOffer">
                {{itemOffer}} Off
            </span>
            <div class="courseShort-dsc d-block">
                <div class="w-100">
                    <h3>{{itemTitle}}</h3>
                    <template v-if="!itemSource">
                        <star-rating :rating="ratings" :read-only="true" :star-size="20" :show-rating="false"></star-rating>
                    </template>
                    <ul>
                        <li>{{ itemLessons }} Lessons</li>
                        <li>{{ itemHours }}+ Hours</li>
                    </ul>
                </div>
                <template v-if="itemSource">
                    <template v-if="value == max || value > max" >
                        <b-progress :max="max" height="1.5rem" class="completed mt-2">
                            <b-progress-bar :value="value">                            
                            </b-progress-bar>
                            <span class="progressStatus">Completed: <strong> 100 / 100 </strong></span>
                        </b-progress>
                    </template>
                    <template v-else>
                        <span class="expireDate" v-if="expiredDate">Expire On: <span> {{expiredDate}} </span></span>
                        <b-progress :max="max" height="1.5rem" class="mt-2">
                            <b-progress-bar :value="value">                            
                            </b-progress-bar>
                            <span  class="progressStatus">Completed: <strong>{{ value }} / {{ max }}</strong></span>
                        </b-progress>
                    </template>
                    
                </template>
                <template v-else>
                    <div v-if="isOffer">
                        <span class="course-price"><del>{{itemPriceDisplay}}</del></span>
                        <span class="course-price">{{ itemOfferPriceDisplay }}</span>
                    </div>
                    <div v-else>
                        <span v-if="price == 0" class="course-price"> Free </span>
                        <span v-else class="course-price">{{ itemPriceDisplay }} </span>
                    </div>
                </template>
            </div>
        </div>
    </router-link>
</template>

<script>
import StarRating from 'vue-star-rating'
export default {
    name: "CourseItemExt",
    components: {
        StarRating
    },
    props:{
        item:{
            required: true,
        },
        source:{
            type: String,
        },
        learningStatus:{
            type: Number,
        },
        expDate:{
            type:String,
        }
    },
    data(){
        return {
            itemSource: this.source,
            value: this.learningStatus,
            expiredStatus: this.expStatus,
            expiredDate: this.expDate,
            max: 100,
            
            itemImages: this.item.image,
            itemTitle:this.item.title,
            itemLessons: this.item.lessons,
            itemHours:this.item.total_hours,
            itemPriceDisplay:this.item.display_price,
            ratings: this.item.ratings,
            price: this.item.price,

            isOffer:this.item.is_offer,
            itemOffer:this.item.offer_percentage,
            itemOfferPriceDisplay:this.item.display_offer_price
        }
    },
    watch: {
        item(value) {
            let app = this;
            app.expStatus       = value.expiration_status;
            app.itemImages      =  value.image;
            app.itemTitle       =  value.title,
            app.itemLessons     = value.lessons,
            app.itemHours       = value.total_hours,
            app.itemPriceDisplay= value.display_price,
            app.ratings         = value.ratings,
            app.price           = value.price,

            app.isOffer         = value.is_offer,
            app.itemOffer       = value.offer_percentage,
            app.itemOfferPriceDisplay = value.display_offer_price

            app.value = value.learning_status;
        },
        source(value){
            let app = this;
            app.itemSource = value;
        },
        learningStatus(value){
            let app = this;
            app.value = value;
        },
        expDate(value){
            let app = this;
            app.expiredDate = value;
        }
    },
}
</script>